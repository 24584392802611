<template>
  <div class="view pa24">
    <div class="operation-left">
      <el-button class="mr10" type="primary" @click="addMemberFun">新增等级</el-button>
    </div>
    <el-alert title="“级别” 用于会员购买更高级的会员时折算会员有效期" type="warning" show-icon>
    </el-alert>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column prop="levelName" align="center" label="等级名称" show-overflow-tooltip />
        <el-table-column prop="levelSort" align="center" width="80" label="级别" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，级别越高" placement="top-start">
              <div>{{ scope.row.levelSort }}级</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="levelTarget" align="center" label="积分价格" />
        <el-table-column show-overflow-tooltip prop="levelPrice" align="center" label="购买价格">
          <template slot-scope="scope">
            {{ scope.row.levelPrice | getPrice }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="levelTime" align="center" label="有效期">
          <template slot-scope="scope">
            {{ scope.row.levelTime | getTime }}
          </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态" width="150">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isHide" active-text="显示" @change="setHideFun($event, scope.row)"
              :active-value="false" inactive-text="隐藏" :inactive-value="true">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <!-- <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="delMemberLevel(scope.row)"
              icon="el-icon-info" title="确定要删除这条会员等级？">
              <el-button slot="reference" style="color: #F56C6C" type="text">删除</el-button>
            </customPopconfirm> -->
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
      :visible.sync="centerDialogVisible" :destroy-on-close="true" width="400px" center>
      <el-form :model="formData" :rules="rules" ref="formData" label-width="78px">
        <el-form-item label="等级名称" prop="levelName">
          <el-input class="w240" v-model="formData.levelName" placeholder="请输入等级名称"></el-input>
        </el-form-item>
        <el-form-item label="级别" prop="levelSort">
          <!-- <el-input class="w240" placeholder="请输入级别" :disabled="formType === 'update'" v-model="formData.levelSort"
            type="number" /> -->
          <el-input type="number" class="w240 suffixInput" title="数值越大，级别越高" v-model="formData.levelSort"
            :disabled="formType == 'update'" placeholder="请输入级别">
            <template slot="append">级</template>
          </el-input>
        </el-form-item>
        <el-form-item label="积分价格" prop="levelTarget">
          <el-input class="w240" type="number" v-model="formData.levelTarget" placeholder="请输入积分价格"></el-input>
        </el-form-item>
        <el-form-item label="购买价格" prop="levelPrice">
          <div class="switchInput">
            <div class="selectType">
              <el-switch v-model="levelPrice" active-text="付款金额" inactive-text="免费" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="levelPrice" v-if="levelPrice === 1">
              <el-input type="number" class="w240 suffixInput" v-model="formData.levelPrice" placeholder="购买价格">
                <template slot="append">元</template>
              </el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="有效期" prop="levelTime">
          <div class="switchInput">
            <div class="selectType">
              <el-switch v-model="levelTime" active-text="有效期" inactive-text="永久" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="levelTime" v-if="levelTime === 1">
              <el-input placeholder="可用天数" class="w240 suffixInput" v-model="formData.levelTime" type="number">
                <template slot="append">天</template>
              </el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="推二返本" prop="twoBack">
          <div class="selectType">
            <el-switch v-model="formData.twoBack" active-text="开启" inactive-text="关闭">
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item label="推一比例" prop="firstScale" v-if="formData.twoBack">
          <el-input type="number" class="w240 suffixInput" v-model="formData.firstScale" placeholder="分享第一个返现比例">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="推二比例" prop="secondScale" v-if="formData.twoBack">
          <el-input type="number" class="w240 suffixInput" v-model="formData.secondScale" placeholder="分享第二个返现比例">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUpdateLevel">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
// import customPopconfirm from "@/components/common/customPopconfirm";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
    // customPopconfirm
  },
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      centerDialogVisible: false,
      tableData: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      modelTitle: "",
      formData: {
        levelName: "",
        levelPrice: 0,
        levelTarget: 0,
        levelTime: 0,
        levelSort: 0,
        isHide: true,
        twoBack: false, //是否开启推二返本 
        firstScale: "", //推一比例 
        secondScale: "", //推二比例 
      },
      levelPrice: 0,
      levelTime: 0,
      rules: {
        levelName: {
          required: true,
          trigger: "blur",
          message: "请输入等级名称",
        },
        levelSort: {
          required: true,
          trigger: "blur",
          message: "请输入级别",
        },
      },
      formType: "",
      levelId: 0,
    };
  },
  filters: {
    getPrice(price) {
      if (price === 0) {
        return "免费";
      } else {
        return price + "元";
      }
    },
    getTime(time) {
      if (time === 0) {
        return "永久";
      } else {
        return time + "天";
      }
    },
  },
  created() {
    this.selectAllMemberLevel();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    /**@method 删除会员等级 */
    delMemberLevel(row) {
      setMemberLevel({
        isDel: 1,
        levelId: row.levelId
      })
        .then((res) => {
          if (res.code === 200) {
            this.selectAllMemberLevel();
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err.message,
            type: "error",
          });
        });
    },
    setHideFun(isHide, row) {
      let params = {
        levelName: row.levelName,
        isHide: isHide,
        levelSort: row.levelSort,
        levelId: row.levelId
      }
      setMemberLevel(params, 9)
        .then((res) => { })
    },
    addUpdateLevel() {
      //修改添加会员等级
      this.$refs.formData.validate((valid) => {
        if (valid) {
          let params = {
            ...this.formData,
            companyId: this.userInfo.companyId,
            // companyId:2
          };
          if (params.twoBack) {
            params.firstScale = params.firstScale / 100;
            params.secondScale = params.secondScale / 100;
          } else {
            params.firstScale = 0
            params.secondScale = 0
          }
          if (this.levelPrice === 0) {
            params.levelPrice = 0;
          }
          if (this.levelTime === 0) {
            params.levelTime = 0;
          }
          if (this.formType === "update") {
            params.levelId = this.levelId;
          }
          setMemberLevel(params, 9)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.centerDialogVisible = false;
                this.selectAllMemberLevel();
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err.message,
                type: "error",
              });
            });
        }
      });
    },
    /**@method 获取会员等级列表 */
    async selectAllMemberLevel(payload) {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        companyId: this.userInfo.companyId,
        // companyId: 2,
        ...payload,
      };
      try {
        this.loading = true;
        const result = await selectAllMemberLevel(params, 9);
        this.loading = false;

        const { data } = result;
        this.tableData = data.pageInfo.list;
        this.total = data.pageInfo.total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.selectAllMemberLevel();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectAllMemberLevel();
    },
    addMemberFun() {
      this.centerDialogVisible = true;
      this.modelTitle = "新增会员等级";
      this.formType = "add";
      this.formData = {
        levelName: "",
        levelPrice: 0,
        levelTarget: 0,
        levelTime: 0,
        levelSort: 0,
        isHide: true,
        twoBack: false, //是否开启推二返本 
        firstScale: "", //推一比例 
        secondScale: "", //推二比例 
      };
      this.levelPrice = 0;
      this.levelTime = 0;
      this.levelId = 0;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      //-console.log(val);
      this.centerDialogVisible = true;
      this.modelTitle = "编辑会员等级";
      this.formType = "update";
      this.levelId = val.levelId;
      if (val.levelPrice) {
        this.levelPrice = 1;
      } else { this.levelPrice = 0; }
      if (val.levelTime) {
        this.levelTime = 1;
      } else { this.levelTime = 0; }
      this.formData = {
        levelName: val.levelName,
        levelPrice: val.levelPrice,
        levelTarget: val.levelTarget,
        levelTime: val.levelTime,
        levelSort: val.levelSort,
        isHide: val.isHide,
        twoBack: val.twoBack, //是否开启推二返本 
        firstScale: val.firstScale * 100, //推一比例 
        secondScale: val.secondScale * 100, //推二比例 
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.switchInput {

  .selectType {
    width: 150px;
  }

  .levelPrice {
    width: 120px;
  }

  .levelTime {
    width: 120px;
  }
}

.operation-left {
  margin-bottom: 20px;

  .el-button {
    width: 95px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    &:active {
      border-color: #51cbcd;
    }
  }
}
</style>